import http from '@/utils/http';
/**
 * 退出登录
 * params:token
 */
export const logout = params => {
	return http({
		url: '/logout',
		method: 'GET',
		params
	})
}

/**
 * 生成图形验证码
 */
export const captchaImageApi = params => {
	return http({
		url: '/captchaImage',
		method: 'GET',
		params
	})
}
/*
校验图形验证码
*/
export const catchImage = params => {
    return http({
        url: '/client/mail/booleCatch',
        method: 'GET',
		params
    })
}
/**
 * 登录
 */
export const loginApi = data => {
	return http({
		url: '/login',
		method: 'POST',
		data
	})
}

/**
 * 谷歌登录
 */
export const googleLoginApi = (params) => {
	return http({
		url: '/googleLogin',
		method: 'POST',
		params
	})
}


/**
 * 注册
 */
export const registerApi = data => {
	return http({
		url: '/register',
		method: 'POST',
		data
	})
}


/**
 * 获取用户信息
 */
export const getInfoApi = params => {
	return http({
		url: '/getInfo',
		method: 'GET',
		params
	})
}

/**
 * 通过邮箱获取验证码
 */
export const getCodeApi = params => {
	return http({
		url: '/client/mail/getCode',
		method: 'GET',
		params
	})
}
/*
激活邮箱
*/
export const codeConfirmEmail = (data) => {
    return http({
		url: '/codeConfirmEmail',
		method: 'POST',
		data
	})
}
/*
校验邮箱验证码(重置密码专用)
*/
export const codeVerify = (data) => {
    return http({
		url: '/client/mail/codeVerify',
		method: 'POST',
		data
	})
}
/*
校验邮箱是否已注册(重置密码专用)
*/
export const booleUserName = params => {
	return http({
		url: '/client/mail/booleUserName',
		method: 'GET',
		params
	})
}
/*
发送邮箱验证码,并且校验邮箱是否可用(注册专用)
*/
export const getEmailCode = params => {
	return http({
		url: '/getEmailCode',
		method: 'GET',
		params
	})
}
/**
 * 通过邮箱重置密码
 */
export const codeUpdatePwdApi = data => {
	return http({
		url: '/client/mail/codeUpdatePwd',
		method: 'POST',
		data
	})
}



