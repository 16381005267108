import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import MataChinsese from '@/assets/json/chineseMeta.js';
import MetaEnglish from '@/assets/json/englishMeta.js';
import cookie from 'js-cookie'
const lang = localStorage.getItem('langrage');
console.log('router页面检测当前语言',lang);

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name:'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/home/index.vue')
      },
    {
        name:'zh/home',
        path: '/zh',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/home/index.vue')
    },
  {

        path: '/treaty/:type',
        name: 'treaty',
        component: () => import(/* webpackChunkName: "treaty" */ '@/views/treaty.vue')
    },
    {
        path: '/zh/treaty/:type',
        name: 'zh/treaty',
        component: () => import(/* webpackChunkName: "treaty" */ '@/views/treaty.vue')
    },
    {
        path: '/all-Usedcases',
        name: 'all-Usedcases',
        component: () => import(/* webpackChunkName: "recommend" */ '@/views/professionm/allUsedcases/index.vue')
    },
  
    {
        path: '/zh/all-Usedcases',
        name: 'zh/all-Usedcases',
        component: () => import(/* webpackChunkName: "recommend" */ '@/views/professionm/allUsedcases/index.vue')
      },
  {
        path: '/referral-program',
        name: 'referral-program',
        component: () => import(/* webpackChunkName: "recommend" */ '@/views/referralProgram/recommend.vue')
    },
    {
        path: '/zh/referral-program',
        name: 'zh/referral-program',
        component: () => import(/* webpackChunkName: "recommend" */ '@/views/referralProgram/recommend.vue')
      },
  {
        path: '/zh/contact-us',
        name: 'zh/contact-us',
        component: () => import(/* webpackChunkName: "contactUS" */ '@/views/country/contactUS.vue')
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import(/* webpackChunkName: "contactUS" */ '@/views/country/contactUS.vue')
      },
  {
        path: '/zh/about-us',
        name: 'zh/about-us',
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/aboutUs.vue')
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/aboutUs.vue')
      },
  
      {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partnership" */ '@/views/referralProgram/partnership.vue')
      },
      {
        path: '/zh/partners',
        name: 'zh/partners',
        component: () => import(/* webpackChunkName: "partnership" */ '@/views/referralProgram/partnership.vue')
      },
 
 
      {
        path: '/locations/france',
        name: 'locations/france',
        component: () => import(/* webpackChunkName: "countryFrance" */ '@/views/country/France/index.vue')
      },
      {
        path: '/zh/locations/france',
        name: 'zh/locations/france',
        component: () => import(/* webpackChunkName: "countryFrance" */ '@/views/country/France/index.vue')
 
  },

      {
        path: '/locations/united-state',
        name: 'locations/united-state',
        component: () => import(/* webpackChunkName: "countryAmerica" */ '@/views/country/America/index.vue')
      },
      {
        path: '/zh/locations/united-state',
        name: 'zh/locations/united-state',
        component: () => import(/* webpackChunkName: "countryAmerica" */ '@/views/country/America/index.vue')
   
  },
 
      {
        path: '/locations/united-kindom',
        name: 'locations/united-kindom',
        component: () => import(/* webpackChunkName: "countryBritain" */ '@/views/country/Britain/index.vue')
      },
      {
        path: '/zh/locations/united-kindom',
        name: 'zh/locations/united-kindom',
        component: () => import(/* webpackChunkName: "countryBritain" */ '@/views/country/Britain/index.vue')
    
  },

      {
        path: '/locations/hongkong',
        name: 'locations/hongkong',
        component: () => import(/* webpackChunkName: "countryHongkong" */ '@/views/country/Hongkong/index.vue')
      },
      {
        path: '/zh/locations/hongkong',
        name: 'zh/locations/hongkong',
        component: () => import(/* webpackChunkName: "countryHongkong" */ '@/views/country/Hongkong/index.vue')
    
    },
    {
        path: '/locations/australia',
        name: 'locations/australia',
        component: () => import(/* webpackChunkName: "countryAustralia" */ '@/views/country/Australia/index.vue')
      },
      {
        path: '/zh/locations/australia',
        name: 'zh/locations/australia',
        component: () => import(/* webpackChunkName: "countryAustralia" */ '@/views/country/Australia/index.vue')
      },
 
      {
        path: '/locations/argentina',
        name: 'locations/argentina',
        component: () => import(/* webpackChunkName: "countryArgentina" */ '@/views/country/Argentina/index.vue')
      },
      {
        path: '/zh/locations/argentina',
        name: 'zh/locations/argentina',
        component: () => import(/* webpackChunkName: "countryArgentina" */ '@/views/country/Argentina/index.vue')
      },
      {
        path: '/locations/philippine',
        name: 'locations/philippine',
        component: () => import(/* webpackChunkName: "countryPhilippines" */ '@/views/country/Philippines/index.vue')
      },
      {
        path: '/zh/locations/philippine',
        name: 'zh/locations/philippine',
        component: () => import(/* webpackChunkName: "countryPhilippines" */ '@/views/country/Philippines/index.vue')
      },
      {
        path: '/locations/canada',
        name: 'locations/canada',
        component: () => import(/* webpackChunkName: "countryCanada" */ '@/views/country/Canada/index.vue')
      },
      {
        path: '/zh/locations/canada',
        name: 'zh/locations/canada',
        component: () => import(/* webpackChunkName: "countryCanada" */ '@/views/country/Canada/index.vue')
    
  },
 
      {
        path: '/locations/germany',
        name: 'locations/germany',
        component: () => import(/* webpackChunkName: "countryGermany" */ '@/views/country/Germany/index.vue')
      },
      {
        path: '/zh/locations/germany',
        name: 'zh/locations/germany',
        component: () => import(/* webpackChunkName: "countryGermany" */ '@/views/country/Germany/index.vue')
    
  },

      {
        path: '/locations/japan',
        name: 'locations/japan',
        component: () => import(/* webpackChunkName: "countryJapan" */ '@/views/country/Japen/index.vue')
      },
      {
        path: '/zh/locations/japan',
        name: 'zh/locations/japan',
        component: () => import(/* webpackChunkName: "countryJapan" */ '@/views/country/Japen/index.vue')
    
  },
  {
    
        path: '/locations/vietnam',
        name: 'locations/vietnam',
        component: () => import(/* webpackChunkName: "countryVietnam" */ '@/views/country/Vietnam/index.vue')
      },
      {
        path: '/zh/locations/vietnam',
        name: 'zh/locations/vietnam',
        component: () => import(/* webpackChunkName: "countryVietnam" */ '@/views/country/Vietnam/index.vue')
   
  },
  {
   
        path: '/locations/brazil',
        name: 'locations/brazil',
        component: () => import(/* webpackChunkName: "countryBrazil" */ '@/views/country/Brazil/index.vue')
      },
      {
        path: '/zh/locations/brazil',
        name: 'zh/locations/brazil',
        component: () => import(/* webpackChunkName: "countryBrazil" */ '@/views/country/Brazil/index.vue')
   
  },
  {
    
        path: '/locations/more',
        name: 'locations/more',
        component: () => import(/* webpackChunkName: "countryMore" */ '@/views/country/More/index.vue')
      },
      {
        path: '/zh/locations/more',
        name: 'zh/locations/more',
        component: () => import(/* webpackChunkName: "countryMore" */ '@/views/country/More/index.vue')
    
  },

 
   
      {
        path: '/products/residential-proxies',
        name: 'products/residential-proxies',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/product.vue')
      },
      {
        path: '/zh/products/residential-proxies',
        name: 'zh/products/residential-proxies',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/product.vue')
      },
      {
        path: '/products/premium-residential-proxies',
        name: 'products/premium-residential-proxies',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/supProduct.vue')
      },
      {
        path: '/zh/products/premium-residential-proxies',
        name: 'zh/products/premium-residential-proxies',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/supProduct.vue')
      },
    
      {
        path: '/prices/premium-residential-proxies-pricing',
        name: 'prices/premium-residential-proxies-pricing',
        component: () => import(/* webpackChunkName: "price" */ '@/views/price/supPrice.vue')
      },
      {
        path: '/zh/prices/premium-residential-proxies-pricing',
        name: 'zh/prices/premium-residential-proxies-pricing',
        component: () => import(/* webpackChunkName: "price" */ '@/views/price/supPrice.vue')
  
    },
    {
        path: '/prices/residential-proxies-pricing',
        name: 'prices/residential-proxies-pricing',
        component: () => import(/* webpackChunkName: "price" */ '@/views/price/price.vue')
      },
      {
        path: '/zh/prices/residential-proxies-pricing',
        name: 'zh/prices/residential-proxies-pricing',
        component: () => import(/* webpackChunkName: "price" */ '@/views/price/price.vue')
      },
  {
    
        path: '/use-cases/price-monitoring',
        name: 'use-cases/price-monitoring',
        component: () => import(/* webpackChunkName: "professionmPrice" */ '@/views/professionm/Price/index.vue')
      },
      {
        path: '/zh/use-cases/price-monitoring',
        name: 'zh/use-cases/price-monitoring',
        component: () => import(/* webpackChunkName: "professionmPrice" */ '@/views/professionm/Price/index.vue')
   
  },
  {
   
        path: '/use-cases/market-research',
        name: 'use-cases/market-research',
        component: () => import(/* webpackChunkName: "professionmMarket" */ '@/views/professionm/Market/index.vue')
      },
      {
        path: '/zh/use-cases/market-research',
        name: 'zh/use-cases/market-research',
        component: () => import(/* webpackChunkName: "professionmMarket" */ '@/views/professionm/Market/index.vue')
   
  },
  {
   
        path: '/use-cases/ad-verificatgion',
        name: 'use-cases/ad-verificatgion',
        component: () =>
          import(/* webpackChunkName: "professionmAdvertisement" */ '@/views/professionm/Advertisement/index.vue')
      },
      {
        path: '/zh/use-cases/ad-verificatgion',
        name: 'zh/use-cases/ad-verificatgion',
        component: () =>
          import(/* webpackChunkName: "professionmAdvertisement" */ '@/views/professionm/Advertisement/index.vue')
     
  },
  {
   
        path: '/use-cases/seo',
        name: 'use-cases/seo',
        component: () => import(/* webpackChunkName: "professionmEngine" */ '@/views/professionm/Engine/index.vue')
      },
      {
        path: '/zh/use-cases/seo',
        name: 'zh/use-cases/seo',
        component: () => import(/* webpackChunkName: "professionmEngine" */ '@/views/professionm/Engine/index.vue')
     
  },
  {
    
        path: '/use-cases/data-collection',
        name: 'use-cases/data-collection',
        component: () => import(/* webpackChunkName: "professionmData" */ '@/views/professionm/Data/index.vue')
      },
      {
        path: '/zh/use-cases/data-collection',
        name: 'zh/use-cases/data-collection',
        component: () => import(/* webpackChunkName: "professionmData" */ '@/views/professionm/Data/index.vue')
     
  },
  {
   
        path: '/use-cases/travel-fare-aggregation',
        name: 'use-cases/travel-fare-aggregation',
        component: () => import(/* webpackChunkName: "professionmTravel" */ '@/views/professionm/Travel/index.vue')
      },
      {
        path: '/zh/use-cases/travel-fare-aggregation',
        name: 'zh/use-cases/travel-fare-aggregation',
        component: () => import(/* webpackChunkName: "professionmTravel" */ '@/views/professionm/Travel/index.vue')
     
  },
  {
    
        path: '/use-cases/sneaker',
        name: 'use-cases/sneaker',
        component: () => import(/* webpackChunkName: "professionmMotion" */ '@/views/professionm/Motion/index.vue')
      },
      {
        path: '/zh/use-cases/sneaker',
        name: 'zh/use-cases/sneaker',
        component: () => import(/* webpackChunkName: "professionmMotion" */ '@/views/professionm/Motion/index.vue')
      
  },
  {
    
        path: '/use-cases/selling',
        name: 'use-cases/selling',
        component: () => import(/* webpackChunkName: "professionmEale" */ '@/views/professionm/Eale/index.vue')
      },
      {
        path: '/zh/use-cases/selling',
        name: 'zh/use-cases/selling',
        component: () => import(/* webpackChunkName: "professionmEale" */ '@/views/professionm/Eale/index.vue')
     
  },
  {
    
        path: '/use-cases/email-protection',
        name: 'use-cases/email-protection',
        component: () => import(/* webpackChunkName: "professionmEmail" */ '@/views/professionm/Email/index.vue')
      },
      {
        path: '/zh/use-cases/email-protection',
        name: 'zh/use-cases/email-protection',
        component: () => import(/* webpackChunkName: "professionmEmail" */ '@/views/professionm/Email/index.vue')
    
  },
  {
    
        path: '/use-cases/social-media',
        name: 'use-cases/social-media',
        component: () => import(/* webpackChunkName: "professionmMarketing" */ '@/views/professionm/Marketing/index.vue')
      },
      {
        path: '/zh/use-cases/social-media',
        name: 'zh/use-cases/social-media',
        component: () => import(/* webpackChunkName: "professionmMarketing" */ '@/views/professionm/Marketing/index.vue')
      
  },
  {
    
        path: '/social-media/chatgpt',
        name: 'social-media/chatgpt',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/useCase/media/ChartGPT/index.vue')
      },
      {
        path: '/zh/social-media/chatgpt',
        name: 'zh/social-media/chatgpt',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/useCase/media/ChartGPT/index.vue')
     
  },
  {
   
        path: '/social-media/discord',
        name: 'social-media/discord',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/useCase/media/Discord/index.vue')
      },
      {
        path: '/zh/social-media/discord',
        name: 'zh/social-media/discord',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/useCase/media/Discord/index.vue')
      
  },
  {

        path: '/social-media/facebook',
        name: 'social-media/facebook',
        component: () => import(/* webpackChunkName: "mediaFacebook" */ '@/views/useCase/media/Facebook/index.vue')
      },
      {
        path: '/zh/social-media/facebook',
        name: 'zh/social-media/facebook',
        component: () => import(/* webpackChunkName: "mediaFacebook" */ '@/views/useCase/media/Facebook/index.vue')
   
  },
  {
  
        path: '/social-media/instagram',
        name: 'social-media/instagram',
        component: () => import(/* webpackChunkName: "mediaInstagram" */ '@/views/useCase/media/Instagram/index.vue')
      },
      {
        path: '/zh/social-media/instagram',
        name: 'zh/social-media/instagram',
        component: () => import(/* webpackChunkName: "mediaInstagram" */ '@/views/useCase/media/Instagram/index.vue')
   
  },
  {
    
        path: '/social-media/pinterest',
        name: 'social-media/pinterest',
        component: () => import(/* webpackChunkName: "mediaPinterest" */ '@/views/useCase/media/Pinterest/index.vue')
      },
      {
        path: '/zh/social-media/pinterest',
        name: 'zh/social-media/pinterest',
        component: () => import(/* webpackChunkName: "mediaPinterest" */ '@/views/useCase/media/Pinterest/index.vue')
    
  },
  {
   
        path: '/social-media/reddit',
        name: 'social-media/reddit',
        component: () => import(/* webpackChunkName: "mediaReddit" */ '@/views/useCase/media/Reddit/index.vue')
      },
      {
        path: '/zh/social-media/reddit',
        name: 'zh/social-media/reddit',
        component: () => import(/* webpackChunkName: "mediaReddit" */ '@/views/useCase/media/Reddit/index.vue')
    
  },
  {
   
        path: '/social-media/tiktok',
        name: 'social-media/tiktok',
        component: () => import(/* webpackChunkName: "mediaTIKTOK" */ '@/views/useCase/media/TIKTOK/index.vue')
      },
      {
        path: '/zh/social-media/tiktok',
        name: 'zh/social-media/tiktok',
        component: () => import(/* webpackChunkName: "mediaTIKTOK" */ '@/views/useCase/media/TIKTOK/index.vue')
   
  },
  {
    
        path: '/social-media/twitch',
        name: 'social-media/twitch',
        component: () => import(/* webpackChunkName: "mediaTwitch" */ '@/views/useCase/media/Twitch/index.vue')
      },
      {
        path: '/zh/social-media/twitch',
        name: 'zh/social-media/twitch',
        component: () => import(/* webpackChunkName: "mediaTwitch" */ '@/views/useCase/media/Twitch/index.vue')
  
  },
  {
 
        path: '/social-media/youtube',
        name: 'social-media/youtube',
        component: () => import(/* webpackChunkName: "mediaYoutube" */ '@/views/useCase/media/Youtube/index.vue')
      },
      {
        path: '/zh/social-media/youtube',
        name: 'zh/social-media/youtube',
        component: () => import(/* webpackChunkName: "mediaYoutube" */ '@/views/useCase/media/Youtube/index.vue')
    
  },
  {
  
        path: '/ecommerce/airbnb',
        name: 'ecommerce/airbnb',
        component: () => import(/* webpackChunkName: "businessOnlineAirbnb" */ '@/views/useCase/businessOnline/Airbnb/index.vue')
      },
      {
        path: '/zh/ecommerce/airbnb',
        name: 'zh/ecommerce/airbnb',
        component: () => import(/* webpackChunkName: "businessOnlineAirbnb" */ '@/views/useCase/businessOnline/Airbnb/index.vue')
    
  },
  {
  
        path: '/ecommerce/amazon',
        name: 'ecommerce/amazon',
        component: () => import(/* webpackChunkName: "businessOnlineAmazon" */ '@/views/useCase/businessOnline/Amazon/index.vue')
      },
      {
        path: '/zh/ecommerce/amazon',
        name: 'zh/ecommerce/amazon',
        component: () => import(/* webpackChunkName: "businessOnlineAmazon" */ '@/views/useCase/businessOnline/Amazon/index.vue')
    
  },
  {
   
        path: '/ecommerce/ebay',
        name: 'ecommerce/ebay',
        component: () => import(/* webpackChunkName: "businessOnlineEBey" */ '@/views/useCase/businessOnline/EBey/index.vue')
      },
      {
        path: '/zh/ecommerce/ebay',
        name: 'zh/ecommerce/ebay',
        component: () => import(/* webpackChunkName: "businessOnlineEBey" */ '@/views/useCase/businessOnline/EBey/index.vue')
    
  },
  {
  
        path: '/ecommerce/etsy',
        name: 'ecommerce/etsy',
        component: () => import(/* webpackChunkName: "businessOnlineEtsy" */ '@/views/useCase/businessOnline/Etsy/index.vue')
      },
      {
        path: '/zh/ecommerce/etsy',
        name: 'zh/ecommerce/etsy',
        component: () => import(/* webpackChunkName: "businessOnlineEtsy" */ '@/views/useCase/businessOnline/Etsy/index.vue')
    
  },
  {

        path: '/ecommerce/mercari',
        name: 'ecommerce/mercari',
        component: () => import(/* webpackChunkName: "businessOnlineMercari" */ '@/views/useCase/businessOnline/Mercari/index.vue')
      },
      {
        path: '/zh/ecommerce/mercari',
        name: 'zh/ecommerce/mercari',
        component: () => import(/* webpackChunkName: "businessOnlineMercari" */ '@/views/useCase/businessOnline/Mercari/index.vue')
   
  },
  {
 
        path: '/ecommerce/shopify',
        name: 'ecommerce/shopify',
        component: () => import(/* webpackChunkName: "businessOnlineShopify" */ '@/views/useCase/businessOnline/Shopify/index.vue')
      },
      {
        path: '/zh/ecommerce/shopify',
        name: 'zh/ecommerce/shopify',
        component: () => import(/* webpackChunkName: "businessOnlineShopify" */ '@/views/useCase/businessOnline/Shopify/index.vue')
   
  },
  {
 
        path: '/ecommerce/vinted',
        name: 'ecommerce/vinted',
        component: () => import(/* webpackChunkName: "businessOnlineVinted" */ '@/views/useCase/businessOnline/Vinted/index.vue')
      },
      {
        path: '/zh/ecommerce/vinted',
        name: 'zh/ecommerce/vinted',
        component: () => import(/* webpackChunkName: "businessOnlineVinted" */ '@/views/useCase/businessOnline/Vinted/index.vue')
  
  },
  {
   
        path: '/ecommerce/walmart',
        name: 'ecommerce/walmart',
        component: () => import(/* webpackChunkName: "businessOnlineWalmart" */ '@/views/useCase/businessOnline/Walmart/inex.vue')
      },
      {
        path: '/zh/ecommerce/walmart',
        name: 'zh/ecommerce/walmart',
        component: () => import(/* webpackChunkName: "businessOnlineWalmart" */ '@/views/useCase/businessOnline/Walmart/inex.vue')
   
    },
  
    // {
    //     path: '/test',
    //     name: 'test',
    //         component: () => import(/* webpackChunkName: "businessOnlineWalmart" */ '@/views/test.vue')
    // },
    // {
    //     path: '/zh/test',
    //     name: 'test',
    //         component: () => import(/* webpackChunkName: "businessOnlineWalmart" */ '@/views/test.vue')
    //   }
];

const router = createRouter({
    //   history: createWebHistory(process.env.BASE_URL),
          history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {

    let lang=''
    let tolang = to.query.lang
    if (tolang == 'en') {
        lang = 'english'
        localStorage.setItem('langrage','english')
    } else if (tolang == 'zh') {
        lang = 'chinese'
        localStorage.setItem('langrage','chinese')
    } else {
        lang=localStorage.getItem('langrage') || 'english'; // 默认语言为英文
    }
    let shouldRedirect = false;
    // 检查是否需要重定向
    //假设本来是中文,点击英文按钮,本地立刻变为英文
    if (lang == 'chinese' && !to.path.startsWith('/zh')) {
      shouldRedirect = true;
      to.path = `/zh${to.path === '/' ? '' : to.path}`; // 构造新的中文路径
    } else if (lang === 'english' && to.path.startsWith('/zh')) {
      shouldRedirect = true;
      to.path = to.path.replace(/^\/zh/, ''); // 构造新的英文路径
    }
    if (shouldRedirect) {
      // 使用 replace 而不是 push 来避免在 history 中留下当前路由的记录
      router.replace(to.path)
    } else {
            next(); // 正常导航 
    }
    setTimeout(() => {
        window.scrollTo(0, 0);
    })
  });
router.isReady().then(() => {});

export default router;
