// axios基础的封装
import axios from 'axios';
import { ElMessage, ElLoading } from 'element-plus';
import _ from 'lodash';

const httpInstance = axios.create({
  baseURL: 'https://iprocket.io/prod-api', // 正式
//   baseURL: 'http://43.134.216.27:9914',
  timeout: 30000
});

//loading对象
let loading;

//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading(target) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    console.log(1212121212112212212);
    
    loading = ElLoading.service({
      lock: true,
      text: 'Loading...',
      background: 'rgba(0, 0, 0, 0.7)',
      target: target || 'body'
    });
  }
  needLoadingRequestCount++;
}

//隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
  if (needLoadingRequestCount === 0) {
    //关闭loading
    toHideLoading();
  }
}

//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = _.debounce(() => {
  loading && loading.close();
  loading = null;
}, 300);

// 拦截器

// axios请求拦截器
httpInstance.interceptors.request.use(
  (config) => {
    // 1. 获取token
    // 2. 按照后端的要求拼接token数据
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    let lang = localStorage.getItem('langrage') == 'chinese' ? 'zh' : 'en';

    config.headers['Accept-Language'] = lang;
    //判断当前请求是否设置了不显示Loading
    // if (config.headers.showLoading !== false) {
    //   showLoading(config.headers.loadingTarget);
    // }
    return config;
  },
  (e) => {
    //判断当前请求是否设置了不显示Loading
    // if (config.headers.showLoading !== false) {
    //   hideLoading();
    // }
    Promise.reject(e);
  }
);

// axios响应式拦截器
httpInstance.interceptors.response.use(
  (response) => {
    //判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
    // if (response.config.headers.showLoading !== false) {
    //   hideLoading();
    // }
    const res = response.data;
    if (!res.code) return res;
    if (res.code !== 200) {
    //   ElMessage({
    //     grouping: true,
    //     type: 'error',
    //     message: res.msg || `errorCode-${res.code}`
    //   });

      if (res.code === 401 || res.code === 403) {
        // to re-login
        //localStorage.clear();
        localStorage.removeItem('userInfo');
        localStorage.removeItem('response');
        localStorage.removeItem('token');
        // store.dispatch('user/resetToken').then(() => {
        //   setTimeout(()=>{
        //   },500);
        // })
        location.reload();
      }
      return Promise.reject(res.msg);
    } else {
      return res;
    }
  },
  (e) => {
    //判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
    // if (e.config.headers.showLoading !== false) {
    //   hideLoading();
    // }
    console.log(e, 'http56===');
    // 统一错误提示
    // ElMessage({
    //   grouping: true,
    //   type: 'error',
    //   message: e.msg || `errorCode-${e.code}`
    // });
    return Promise.reject(e);
  }
);

export default httpInstance;
