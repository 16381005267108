<template>
    <metainfo></metainfo>
    <router-view />
</template>
<script setup>
import '@/assets/css/baase.less'
import '@/assets/css/common.less'
import '@/assets/css/price.less'
import '@/assets/css/product.less'
import '@/assets/css/country.less'
import '@/assets/css/animation.css'
import Btnjs from '@/assets/plugins/button.js'
import chinese from '@/assets/json/chinese.js'
import english from '@/assets/json/english.js'
import MataChinsese from '@/assets/json/chineseMeta.js'
import MetaEnglish from '@/assets/json/englishMeta.js'
import { provide, onMounted, watch, nextTick } from 'vue'
import { useMeta } from 'vue-meta'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'

let route = useRoute()
let router = useRouter()
let lang = localStorage.getItem('langrage')
console.log(lang, '目前的语言');

let btnEvent = new Btnjs()
provide('btnEvent', btnEvent)
if (lang === 'english' || !lang) {
    provide('langrage', english)
} else {
    provide('langrage', chinese)
}
onMounted(() => {
    initLivechat().then(() => {
        console.log('livechat加载成功');
        document.dispatchEvent(new Event('render-event'))
    }).catch((err) => {
        console.log('livechat加载出错', err);
    })

})
const initLivechat = () => {
    return new Promise((resolve, reject) => {
        const getWindow = () => window
        getWindow().__lc = getWindow().__lc || {};
        getWindow().__lc.license = 18502704;
        getWindow().__lc.integration_name = 'manual_channels';
        getWindow().__lc.product_name = 'livechat';
        console.log(getWindow().__lc);
        (function (n, t, c) {
            function i(n) {
                return e._h ? e._h.apply(null, n) : e._q.push(n);
            }
            var e = {
                _q: [],
                _h: null,
                _v: '2.0',
                on: function () {
                    i(['on', c.call(arguments)]);
                },
                once: function () {
                    i(['once', c.call(arguments)]);
                },
                off: function () {
                    i(['off', c.call(arguments)]);
                },
                get: function () {
                    if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                    return i(['get', c.call(arguments)]);
                },
                call: function () {
                    i(['call', c.call(arguments)]);
                },
                init: function () {
                    getWindow().__lc.license = 18502704;
                    window.__lc.license = 18502704;
                    const ns = t.createElement('script');
                    console.log(ns, window, getWindow());
                    (ns.async = !0),
                        (ns.type = 'text/javascript'),
                        (ns.src = 'https://cdn.livechatinc.com/tracking.js'),
                        ns.onload = () => {
                            resolve("LiveChat script loaded")
                            console.log('LiveChat script loaded');
                            // 可以在这里初始化 LiveChat，如果有需要的话
                        };
                    t.head.appendChild(ns);
                }
            };
            !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
        })(getWindow(), getWindow().document, [].slice);
    })
}
// 解决el-table ResizeObserver loop completed with undelivered notifications.报错
const debounce = (fn, delay) => {
    let timer = null
    return function () {
        let context = this
        let args = arguments
        clearTimeout(timer)
        timer = setTimeout(function () {
            fn.apply(context, args)
        }, delay)
    }
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16)
        super(callback)
    }
}
</script>
