import http from '@/utils/http';
/*
查询提醒按钮状态
*/
export const getFavorInfo = (params) => {
    return http({
      url: '/client/sysremind/getFavorInfo',
      method: 'GET',
      params
    });
};
/*
在登录时查询用户是否需要提醒
*/
export const remindEmail = (params) => {
    return http({
      url: '/client/sysremind/remindEmail',
      method: 'GET',
      params
    });
};
  /*
  提交提醒按钮数据
  */
  export const updateFavor = (data) => {
    return http({
      url: '/client/sysremind/updateFavor',
      method: 'POST',
      data: data
    });
  };
/**
 * 查询公告信息
 */
export const getFontMsgApi = (params) => {
  return http({
    url: '/client/fontMesg/getFontMsg',
    method: 'GET',
    params
  });
};

/**
 * 生成兑换码
 */
export const getRedeemCodeApi = (data) => {
  return http({
    url: '/clientb/sysexchange/getRedeemCode',
    method: 'POST',
    params: data
  });
};

/**
 * 兑换流量
 */
export const allocateRedeemCodeGb = (data) => {
  return http({
    url: '/clientb/sysexchange/allocateRedeemCodeGb',
    method: 'POST',
    params: data
  });
};

/**
 * 流量使用统计
 */
export const flowGbFoldApi = (params) => {
  return http({
    url: '/clientb/useflow/flowGbFold',
    method: 'GET',
    params
  });
};

/**
 * 流量使用统计小时
 */
export const flowGbFoldByTimeApi = (params) => {
  return http({
    url: '/clientb/useflow/flowGbFoldBuyTime',
    method: 'GET',
    params
  });
};

/**
 * 使用兑换码兑换流量
 */
export const allocateRedeemCodeGbApi = (data) => {
  return http({
    url: '/clientb/sysexchange/allocateRedeemCodeGb',
    method: 'POST',
    params: data
  });
};
/**
 * 企业流量分配
 */
export const updateDistribute = (data) => {
  return http({
    url: '/clientb/syssubuser/firmGbrReturn',
    method: 'POST',
    params: data
  });
};

export const getAgencyList = (params) => {
  return http({
    url: '/clientb/sysagency/getAgencyList',
    method: 'GET',
    params
  });
};

/**
 * 企业计划查询兑换日志
 */
export const firmPlanListApi = (params) => {
  return http({
    url: '/clientb/sysexchange/firmPlanList',
    method: 'GET',
    params
  });
};

// 获取轮播图
export const getBannerListApi = () => {
  return http({
    url: '/client/extendphoto/getphotoList',
    method: 'GET'
  });
};

// 记录点击次数(团队)
export const recordClickApi = (data) => {
  return http({
    url: 'client/codeSource/saveCount',
    method: 'POST',
    params: data
  });
};
// 记录点击次数(个人)
 export const recordClickApiPerson = (data) => {
   return http({
     url: '/client/invite/saveCount',
     method: 'POST',
     params: data
   });
 };